html {
  height: 100%;
}
body {
  height: 100%;
  background: #f2f2f2;
  color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
a {
  text-decoration: none;
}
.App {
}
#products-container {
  width: 60%;
  display: block;
  overflow: auto;
  text-align: center;
  margin: 0 auto;
}

#drops-container {
  width: 60%;
  display: block;
  overflow: auto;
  text-align: center;
  margin: 0 auto;
}

#user-container {
  width: 51%;
  display: block;
  overflow: auto;
  margin: 0 auto;
}

.react-datepicker-wrapper {
  width: 100%;
}


#sync-products-btn, #sync-drops-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #000;
  box-sizing: border-box;
  border-radius: 17px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  margin-top: 2em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}
#sync-products-btn:hover, #sync-drops-btn:hover {
  background: #000;
  color: #fff
}
#sync-products-btn div, #sync-drops-btn div {
  padding-top: 4px;
}

#new-user-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  margin-top: 2em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}
#new-user-btn:hover {
  background: #fff;
  color: #333;
}
#new-user-btn div {
  padding-top: 5px;
}

.product {
  margin: 0 auto;
  margin-top: 3em;
  text-align: center;
  width: auto;
  display: inline-block;
  margin-right: 3em;
}
.hero-product-container {
  float: left;
  position: relative;
}
.hero-product-image-container {
  cursor: pointer;
  width: 250px;
  height: 250px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  text-align: center;
  transition: 0.15s ease-in-out all;
  font-family: 'Helvetica', sans-serif;
  position: relative;
}
.hero-drop-container {
  cursor: pointer;
  width: 250px;
  height: 250px;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  text-align: center;
  transition: 0.15s ease-in-out all;
  font-family: 'Helvetica', sans-serif;
  display: inline-block;
  margin-right: 2em;
  position: relative;
}

#drops-container .drop-details {
  border: none !important;
}



.product-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 210px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  padding-left: 15px;
  color: #000;
}

.drop-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 210px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  padding-left: 15px;
  color: #000;
}

.hero-drop-container .drop-title {
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}

.hero-product-container .product-details {
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
}

.hero-product-image-container:hover {
  border: 1.5px solid #fff;
}
.hero-product-image-container img {
  /* width: 65%;
  height: 65%; */
  max-height: 195px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 10px;
}
.hero-drop-container img {
  max-height: 195px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 10px;
}

#Landing #join-btn-container {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
}
#Landing #join-waitlist-btn {
  background: #867ef7;
  color: #000;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  width: 180px;
  margin: 0 auto;
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
}

.placeholder-pic {
  padding-top: 80px;
  padding-right: 12px;
}

#hero-logo-container {
  margin-top: 2em;
}
#hero-logo {
  width: 50px;
  margin-left: 40px;
}

#Landing #hero-logo-container {
  display: block;
  overflow: hidden;
  text-align: center;
}

#Landing #hero-logo {
  width: 200px;
  margin: 0 auto;
  margin-top: 30vh;
}


#JoinWaitlist, #ControlTower .counter-container {
  margin-top: 4em;
  margin-left: 2em;
  display: block;
  margin-bottom: 1em;
  width: 100%;
  float: left;
}

#JoinWaitlist, #ControlTower .counter-title {
  font-family: 'Avenir', sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 30px;
  padding-left: 0.75em;
  margin-bottom: 15px;
}

#JoinWaitlist, #ControlTower .counter {
  float: left;
  margin-left: 0.25em;
  font-family: 'Avenir', sans-serif;
  color: #000;
  font-size: 70px;
}

.input-container input, textarea {
  font-family: 'Avenir', sans-serif;
}

.select-container {
  margin-bottom: 25px;
}

.product .pre-order-checkout-btn {
  background: #867ef7;
  color: #000;
  font-family: 'Avenir', sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  width: 180px;
  margin: 0 auto;
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
}


#user-btn {
  width: 40px;
  height: 40px;
  float: right;
  margin-top: 2em;
  margin-right: 2em;
  border: 0.5px solid #f2f2f2;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
}


#ControlTower #menu {
  position: absolute;
  top: 0;
  /*left: 0;*/
  right: 0;
  bottom: 0;
  margin-top: 6em;
  text-align: center;
  width: 66%;
  float: right;
}


#ControlTower .menu-card {
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 16px;
  color: #000;
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  transition: 0.15s ease-in-out all;
  cursor: pointer;
  display: inline-block;
  margin-right: 3em;
  margin-bottom: 2em;
}
#ControlTower .menu-card:hover {
  box-shadow: none;
}
#ControlTower .menu-details {
  margin-top: 45px;
}

#ControlTower #hero-product {
  width: 90px;
  margin: 0 auto;
  transform: rotate(45deg);
  margin-top: -15px;
}

#ControlTower #hero-drop {
  width: 80px;
  margin: 0 auto;
  margin-top: 25px;
}

#ControlTower #hero-user {
  width: 80px;
  margin: 0 auto;
  margin-top: 20px;
}


#ControlTower #hero-order {
  width: 80px;
  margin: 0 auto;
  margin-top: 25px;
  margin-left: 20px;
}

#ControlTower #hero-config {
  width: 70px;
  margin: 0 auto;
  margin-top: 25px;
}

#ControlTower #hero-press {
  width: 70px;
  margin: 0 auto;
  margin-top: 25px;
}

#ControlTower #hero-voting {
  width: 70px;
  margin: 0 auto;
  margin-top: 25px;
}

#ControlTower #hero-locations {
  width: 70px;
  margin: 0 auto;
  margin-top: 25px;
}

#ControlTower #hero-requests {
  width: 70px;
  margin: 0 auto;
  margin-top: 25px;
}



#Product #product-container {
  margin: 0 auto;
  display: block;
  width: 100%;
  overflow: hidden;
}

#Drop #drop-container {
  margin: 0 auto;
  display: block;
  width: 100%;
  overflow: hidden;
}



#faqs-container {
  margin: 0 auto;
  display: block;
  width: 100%;
  overflow: hidden;
}

.split-input {
  display: block;
  overflow: hidden;;
}
.split-left {
  display: inline-block;
  width: 49%;
  float: left;
}
.split-right {
  display: inline-block;
  width: 49%;
  float: right;
}

#product-missing, #drop-missing, #no-users {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 48px;
  padding: 10px;
  margin-top: 3em;
  color: #000;
  text-align: center;
}
.no-levels {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 48px;
  padding: 10px;
  margin-top: 3em;
  color: #000;
  text-align: center; 
}
.no-content {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 28px;
  padding: 10px;
  color: #000;
  text-align: center; 
}

#product-container {
  width: 40%;
  margin: 0 auto;
}


#drop-container {
  width: 40%;
  margin: 0 auto;
}

#users-container {
  width: 40%;
  margin: 0 auto;
}

#settings-container {
  width: 40%;
  margin: 0 auto;
}


#product .product-details {
  border-radius: 16px;
  border: 2px solid #333;
  padding: 50px;
  margin-top: 20px;
}

#settings .settings-details {
  border-radius: 16px;
  border: 2px solid #333;
  padding: 50px;
  margin-top: 20px;
}


.drop-details {
  border-radius: 16px;
  border: 2px solid #333;
  padding: 50px;
  margin-top: 20px;
}



#user .user-details {
  border-radius: 16px;
  border: 2px solid #333;
  padding: 50px;
  margin-top: 20px;
}


#drop-info .drop-level-details {
  border-radius: 16px;
  border: 2px solid #333;
  padding: 50px;
  padding-top: 5px;
  padding-bottom: 25px;
  margin-top: 20px;
}

.drop-level-details .input-container, .select-input-container {
  margin-top: 25px;
}


.product-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 200px;
  min-height: 200px;
}
.hero-product-container .product-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 150px;
  min-height: 100px;
  padding: 20px;
}


.image-container .drop-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 206px;
  min-height: 220px;
  margin: 0 auto;
}

.image-container {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 40px;
}

.delete-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 2px 8px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin: 0 auto;
  margin-top: 15px;
  transition: 0.15s ease-in-out all;
  padding-top: 5px;
}
.delete-btn:hover {
  background: red;
  color: #fff;
  border: 1px solid red;
}



#save {
  float: right;
  width: 40px;
  height: 40px;
  float: right;
  margin-top: -10px;
  margin-right: 2em;
  border: 0.5px solid #f2f2f2;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  transition: 0.15s ease-in-out all;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#save:hover {
  box-shadow: none;
}

#save div {
  font-size: 25px;
  padding-top: 3px;
  padding-left: 7px;
}

.external-link {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-right: 2em;
  border: 0.5px solid #f2f2f2;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  transition: 0.15s ease-in-out all;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.external-link:hover {
  box-shadow: none;
}

.external-link div {
  font-size: 25px;
  padding-top: 4px;
  padding-left: 7px;
}



#save-achievement, #save-faqs {
  float: right;
  width: 40px;
  height: 40px;
  float: right;
  margin-top: 14px;
  border: 0.5px solid #f2f2f2;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  transition: 0.15s ease-in-out all;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 40px;
}
#save-achievement:hover, #save-faqs:hover {
  box-shadow: none;
}

#save-achievement div, #save-faqs div {
  font-size: 25px;
  padding-top: 3px;
  padding-left: 7px;
}

.input-container {
  margin-bottom: 1em;
  display: block;
  overflow: hidden;
}

.select-input-container {
  margin-bottom: 2px;
  display: block;
  overflow: hidden;
}

section label {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 3em;
  letter-spacing: 0.10em;
  color: #000;
}


.product-section, .drop-section, .user-section {
  margin-bottom: 4em;
}
.product-section #save {
  margin-top: -5px !important;
}

#product-label {
  display: inline-block;
  float: left;
  margin-right: 1em;
}

.option-info {
  font-family: 'Helvetica', sans-serif;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 1em;
  letter-spacing: 0.10em;
}

.option-info-label {
  display: inline-block;
  color: #000;
}

.option-info-value {
  display: inline-block;
  margin-left: 0.5em;
  color: #000;
}


.input-container label {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.10em;
  color: #000;
}

.input-container input, textarea {
  padding: 20px;
  width: 400px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #f2f2f2;
}

.select-input-container label {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.10em;
  color: #000;
}

.select-input-container input, textarea {
  padding: 20px;
  width: 90%;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #f2f2f2;
}

.user-drop {
  cursor: pointer;
}

.user-drop .user-drop-details {
  border-bottom: 1px solid #333;
  color: #000;
  padding-bottom: 15px;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.10em;
}

.inline-input-container {
  display: inline-block;
  float: left;
  margin-right: 45px;
}


/* 
.full-input input, textarea {
  width: 95%;
} */

.qa-container {
  position: relative;
}

.qa-container .full-input {
  margin-top: 20px;
}

#file-upload-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 2px 8px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 1em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin-top: 10px;
}
#file-upload-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}
#add-social-post-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 2px 8px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 1em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin-top: 10px;
}
#add-social-post-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}
#gallery-image, #icon-image, #reward-image, #info-image, #drop-image {
  display: none !important;
}

input:focus, textarea:focus {
  outline: none !important;
}

.sub-section-title {
  height: 35px;
}

#actions {
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 2em;
  height: 90px;
  padding-top: 10px;
}

.checkbox-toggle {
	 width: 90px;
	 margin-bottom: 10px;
   margin-top: 15px;
	 float: left;
}
 .checkbox-toggle__input {
	 display: none;
}
 .checkbox-toggle__input + .checkbox-toggle__label {
	 display: block;
	 position: relative;
	 transition: 0.3s;
	 border-radius: 50px;
	 background-color: #c7c7c7;
	 height: 33px;
	 margin-bottom: 0;
	 cursor: pointer;
}
 .checkbox-toggle__input + .checkbox-toggle__label:before {
	 transition: 0.3s;
	 content: "";
	 display: block;
	 position: absolute;
	 border-radius: 50%;
	 background-color: #fff;
	 width: 22px;
	 height: 22px;
	 top: 5px;
	 left: 5px;
}
 .checkbox-toggle__value {
	 display: block;
	 float: left;
	 width: 50%;
	 font-size: 11px;
	 text-align: center;
	 margin-top: 27px;
	 letter-spacing: 1px;
	 line-height: 1.5;
}
 .checkbox-toggle__value.left {
	 text-align: left;
	 font-weight: bold;
}
 .checkbox-toggle__value.right {
	 text-align: right;
}

input[name="css"] {
  font-family: 'Courier New', Courier, monospace !important;
}


 .checkbox-toggle__input:checked + .checkbox-toggle__label {
	 background-color: #000;
}
 .checkbox-toggle__input:checked + .checkbox-toggle__label:before {
	 left: calc(100% - 22px - 5px);
}
 .checkbox-toggle__input:checked + .checkbox-toggle__label .right {
	 font-weight: bold;
}
 .checkbox-toggle__input:checked + .checkbox-toggle__label .left {
	 font-weight: normal;
}
 .checkbox-toggle--blue .checkbox-toggle__input:checked + .checkbox-toggle__label {
	 background-color: #2a9db3;
}
 .checkbox-toggle--alert .checkbox-toggle__input:checked + .checkbox-toggle__label {
	 background-color: #cf201b;
}



.css-yk16xz-control {
  min-height: 69px !important;
}
.css-1pahdxg-control {
  min-height: 69px !important;
}

.side-caret {
  float: right;
  transform: rotate(270deg);
  cursor: pointer;
  margin-top: 10px;
}
.down-caret {
  float: right;
  cursor: pointer;
  margin-top: 10px;
}

.divider {
  border: 1px solid #333;
  margin-top: 25px;
  margin-bottom: 40px;
}

.spacer {
  display: block;
  height: 5em;
  overflow: hidden;
}

#page-nav {
  float: left;
  margin-top: 6.5em;
}

.nav-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  margin-top: 2em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}
.nav-btn:hover {
  border: 1.5px solid #000;
  background: #000;
  color: #fff;
}

.active-nav-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #000;
  background: #000;
  color: #fff;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  margin-top: 2em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
}
.active-nav-btn:hover {
  border: 1.5px solid #000;
  background: #000;
  color: #fff;
}



.loader,
.loader:before,
.loader:after {
  background: #000;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #000;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


#add-level-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #000;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: right;
}

#add-level-btn:hover {
  background: #000;
  color: #fff;
  border: 1.5px solid #000;
}

.level-header, .faqs-header {
  display: block;
  overflow: hidden;
  margin-bottom: 2px;
  padding-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  height: 60px;
}

.level-header label, .faqs-header label {
  float: left;
  margin-bottom: 0;
}

.section-header {
  display: block;
  overflow: hidden;
  margin-bottom: 2px;
  padding-bottom: 10px;
  margin-top: 20px;
  width: 100%;
  height: 45px;
}

.product-section .section-header {
  overflow: auto;
  padding-top: 10px;
}

.section-header label {
  float: left;
  margin-bottom: 0;
}


.level-header .delete-btn {
  margin-right: 15px;
  margin-top: 20px;
  float: right;
}

#levels .level-header {
  height: auto;
  padding-bottom: 0px;
}

.level-settings {
  float: left;
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
}

.level-settings img {
  height: 25px;
  padding-top: 3px;
}

.level-number-sub-text {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.10em;
  padding-top: 5px;
  color: #333;
  display: block;
}

.stick-left {
  float: left;
  display: inline-block;
}

.section-header .stick-left {
  padding-top: 12px;
}
.section-header #save {
  margin-top: 0;
}

.cancel-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #FFFFFF;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 30px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: right;
}

.cancel-btn:hover {
  background: #fff;
  color: #000;
  border: 1px solid #fff;
}


.add-achievement-btn {
  width: 140px;
  height: 32.19px;
  border: 1.5px solid #333;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: left;
  margin-left: 10px;
}

.add-achievement-btn:hover {
  background: #000;
  color: #fff;
  border: 1.5px solid #000;
}

.level-achievements {
  min-height: 200px;
  margin-top: -3px;
}

.achievement-image {
  margin-top: 20px;
  margin-left: 20px;
}

.achievement {
  margin: 0 auto;
  margin-top: 3em;
  text-align: center;
  width: auto;
  display: inline-block;
  margin-right: 3em;
}
.hero-achievement-container {
  float: left;
}
.hero-achievement-image-container {
  cursor: pointer;
  width: 250px;
  height: 250px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  text-align: center;
  transition: 0.15s ease-in-out all;
  font-family: 'Helvetica', sans-serif;
}

.hero-achievement-image-container:hover {
  border: 1.5px solid #000;
}
.hero-achievement-image-container img {
  width: 50%;
  height: 50%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.css-26l3qy-menu {
  z-index: 1000000;
}

.achievement-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 210px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  padding-left: 15px;
  color: #000;
}

#delete-description {
  text-align: center;
  font-size: 23px;
  color: #000;
  font-weight: 500;
  padding-top: 25px;
  font-family: 'Helvetica', sans-serif;
}

#delete-description-info {
  text-align: center;
  font-size: 19px;
  color: #fff;
  font-weight: 500;
  padding-top: 10px;
  font-family: 'Helvetica', sans-serif;
}

#level-settings-info .delete-btn {
  margin-top: 150px;
}

#users-container .user {
  border-radius: 16px;
  border: 1px solid #333;
  padding: 50px;
  padding-top: 5px;
  padding-bottom: 25px;
  margin-top: 20px;
}

.user-details {
  padding-top: 20px;
}

.user-details .phone-number {
  color: #333;
  font-weight: 500;
  font-size: 25px;
  text-align: left;
  font-family: 'Helvetica', sans-serif;
}



#new-user-phone-input-container {
  width: 320px;
  margin: 0 auto;
  font-family: 'Helvetica', sans-serif;
  text-align: center;
  margin-top: 7em;
}

#new-user-phone-input-container label {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 22px;
}

#new-user-phone-input-container input, #new-user-phone-input-container input:focus {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  font-family: 'Helvetica', sans-serif;
  width: 90%;
}

 .input-container input, input:focus {
  padding: 20px 20px;
  border: none;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  font-family: 'Helvetica', sans-serif;
}

.full-input .input-container input, .full-input .input-container input:focus {
  width: 90%;
}

.label-container {
  height: 55px;
}

.submit-btn {
  background: #000;
  color: #fff;
  font-family: 'Helvetica', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding: 10px 20px;
  width: 90%;
  margin : 0 auto;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.15s ease-in-out all;
}

.submit-btn:hover {
  background: #6d64bb;
}

#new-user-phone-input-container .submit-btn {
  margin-top: 20px;
}

#resend-code {
  text-align: left;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding-top: 15px;
  font-family: 'Helvetica', sans-serif;
  cursor: pointer;
}

.section-header .add-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 30px;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: right;
}

.section-header .add-btn:hover {
  background: #000;
  color: #fff;
  border: 1.5px solid #000;
}

#user-drop-section .section-header {
  height: 60px;
}

#user-drop-section .sub-section-title {
  color: #fff;
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.user-achievement {
  border-radius: 16px;
  border: 1px solid #333;
  padding: 20px;
  margin-top: 20px;
  display: block;
  overflow: hidden;
}

.inline-input {
  display: inline-block;
  float: left;
  margin-right: 1em;
}

.right-inline-input {
  display: inline-block;
  float: right;
  margin-left: 1em;
}


.user-achievement-title-container {
  padding-top: 26px;
  width: 200px;
}
.user-achievement label {
  font-size: 18px;
}

.user-achievement input, .user-achievement input:focus {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  font-family: 'Helvetica', sans-serif;
  width: 90%;
  margin-top: 12px;
}
#user-drop-section .delete-btn {
  width: 160px;
  margin-top: 300px;
}

#got-the-key-can-you-unlock-it {
  font-size: 22px;
  padding-left: 7px;
  padding-top: 5px;
}

#howdy {
  font-size: 22px;
  padding-left: 9px;
  padding-top: 4px;
}

#login-input-container {
  width: 320px;
  margin: 0 auto;
  font-family: 'Helvetica', sans-serif;
  text-align: center;
  margin-top: 7em;
}

#login-input-container label {
  color: #333;
  text-align: left;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 22px;
}

#login-input-container input, #login-input-container input:focus {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  font-family: 'Helvetica', sans-serif;
  width: 90%;
}

#login-input-container .label-container {
  text-align: left;
  height: 36px;
}
#user-email-input {
  margin-bottom: 19px;
}
#user-pass-input {
  margin-bottom: 19px;
}

.visible {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 3.5em;
  top: 1em;
}

.remove-achievement-answer {

}

.correct-answer-container {
  float: left;
  margin-top: 65px;
  margin-left: 20px;
  transition: 0.15s ease-in-out all;
  padding-top: 5px;
  display: inline-block;
}

.correct-answer-select {
  background: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;  
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2px;
}
.correct-answer-text {
  color: #fff;
  padding-top: 5px;
  text-align: center;
  font-size: 14px;
}

.remove-achievement-answer {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 2px 8px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin: 0 auto;
  margin-top: 71px;
  margin-left: 40px;
  transition: 0.15s ease-in-out all;
  padding-top: 5px;
  display: inline-block;
}

input[name="tags"] {
  width: 90%;
}

#achievement-tags-container {
  margin-top: 20px;
}

.achievement-tag {
  background: #000;
  color: #000;
  font-weight: 500;
  border-radius: 4px;
  width: auto;
  display: inline-block;
  padding: 20px;
  margin-right: 15px;
  position: relative;
}

.achievement-tag-name {

}

.tag-delete {
  border-width: 15px;
  border-style: solid;
  border-color: #fff #fff transparent transparent;
  color: #000;
  position: absolute;
  top:0;
  right:0;
  width:0;
  height:0;
  cursor: pointer;
}

.tag-delete:hover {
  border-color: red red transparent transparent;
  color: #fff;
}



.tag-delete div {
  font-size: 12px;
  position: absolute;
  top: -14px;
  right: -10px;
}

#faqs-container {
  margin-top: 1em;
}

#faqs-container .drop-details {
  padding: 20px 50px;
}

.faq-label {
}



#add-faq-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: right;
}

#add-faq-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}


#add-press-release-btn {
  width: 145px;
  height: 32.19px;
  border: 1.5px solid #333;
  padding-top: 5px;
  box-sizing: border-box;
  border-radius: 17px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 7.5em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
  float: right;  
}


#add-press-release-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}


.post-platform {
  font-weight: 600 !important;
  text-transform: capitalize !important;
}


.post-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 206px;
  min-height: 220px;
  margin: 0 auto;
}

#sweepstakes-actions {
  margin-top: 20px;
  margin-bottom: 25px;
}

.sweepstakes-switch {
  display: inline-block;
  font-size: 15px;
  color: #585858;
  cursor: pointer;
}
.active-sweepstakes-switch {
  color: #000;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.switch-divider {
  display: inline-block;
  color: #585858;
  margin-left: 10px;
  margin-right: 10px;
}
#entries-container {
  display: block;
  overflow: hidden;
  width: 100%;
}

.entry-container {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding: 25px 30px;
  line-height: 1.5em;
  position: relative;
}

.entry-container .basic-info {
  display: inline-block;
  float: left;
  font-weight: 500;
  padding-top: 14px;
  width: 50%;
}
.entry-container .entry-address {
  display: inline-block;
  float: right;
  width: 280px;
}

.selected-entry {
  position: absolute;
  top: 15px;
  right: 25px;
}

#draw-winners-btn, #confirm-winners-btn, #reselect-winners-btn, #archive-entries-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 3px 10px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 1em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin-top: 10px;
}
#draw-winners-btn:hover, #confirm-winners-btn:hover, #reselect-winners-btn:hover, #archive-entries-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

#draw-winners-form {
  display: block;
  overflow: hidden;
  width: 100%;
}

#save-winners {
  width: 40px;
  height: 40px;
  margin-top: -10px;
  border: 0.5px solid #f2f2f2;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px, rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
  transition: 0.15s ease-in-out all;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#save-winners:hover{
  box-shadow: none;
}

#save-winners div {
  font-size: 25px;
  padding-top: 3px;
  padding-left: 7px;
}

.trash-entry {
  position: absolute;
  bottom: 20px;
  right: 80px;
  width: 20px;
  cursor: pointer;
}


.trash-faq {
  position: absolute;
  bottom: 0px;
  right: -30px;
  width: 20px;
  cursor: pointer;
}

.trash-release {
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 20px;
  cursor: pointer;
}

.swap-entry {
  position: absolute;
  top: 20px;
  right: 80px;
  width: 20px;
  cursor: pointer;
}

.label-collection {
  display: inline-block;
}

.release-details {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 40px 40px;
  margin-top: 1em;
  position: relative;
}

.release-options input, .release-options textarea  {
  width: 100%;
}

.release-image {
  min-height: 150px;
  position: relative;
}

.release-file-input {
  display: none;
}

.election-details {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 40px 40px;
  margin-top: 1em;
  position: relative;
}

.election-details input, .election-details textarea  {
  width: 100%;
}


.file-upload-btn {
  width: 109px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 2px 8px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 1em;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  margin-top: 10px;
}
.file-upload-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid rgb(146, 142, 142);
}

.file-container label:first-child {
  font-family: 'Helvetica', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.10em;
  color: #000;
}

#media-content .faqs-header {
  overflow: initial;
}

#locations-upload {
  display: none;
}

#download-requests-file-btn {
  cursor: pointer;
  width: 200px;
  padding: 20px;
  padding-top: 30px;
  border-radius: 12px;
  background: #fff;
  height: 35px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 7px 16px 0px,
  rgba(0, 0, 0, 0.07) 0px 3px 6px 0px;
}


#add-locations-btn {
  width: 160px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 5px 8px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 40px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
}
#add-locations-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

#sync-locations-btn {
  width: 160px;
  height: 32.19px;
  border: 1.5px solid #333;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 5px 8px;
  color: #333;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  font-family: 'Avenir', sans-serif;
  margin-left: 40px;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
  display: inline-block;
}
#sync-locations-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

#locations-table th {
  font-weight: 500;
  padding: 20px;
}


@media only screen and (min-width: 1700px){
  #menu {
    width: 80% !important;
  }
  #user-achievements {
    width: 60%;
    margin: 0 auto;
  }
}


